import { render, staticRenderFns } from "./newaddress.vue?vue&type=template&id=95a49520&scoped=true&"
import script from "./newaddress.vue?vue&type=script&lang=js&"
export * from "./newaddress.vue?vue&type=script&lang=js&"
import style0 from "./newaddress.vue?vue&type=style&index=0&id=95a49520&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95a49520",
  null
  
)

export default component.exports